<template>
  <a-modal v-model:visible="innerVisible"
           title="选择活码"
           width="400px"
           :footer="null"
           @cancel="$emit('update:visible', false)">

    <a-input-search v-model:value="searchForm.name"
                    class="mb-8"
                    placeholder="请输入渠道活码标题"
                    @change="searchCodeByName" />

    <ul ref="listRef"
        class="overflow-y-auto"
        style="height: 240px"
        @scroll="loadMoreCode">
      <div v-for="code in livecode.list"
           :key="code.id"
           class="flex justify-between items-center py-8 px-16 text-color-333 cursor-pointer hover:bg-blue-1"
           :class="{'bg-blue-1': selectedCode.id === code.id}"
           @click="selectCode(code)">
        <li v-html="highlight(code.name, searchForm.name)"
            class="truncate"
            style="width: 280px;">
        </li>
        <svg-icon v-show="selectedCode.id === code.id"
                  class="text-admin-primary"
                  type="iconxuanzhong" />
      </div>

      <a-spin v-if="livecode.loading"
              class="block text-center" />

    </ul>
  </a-modal>
</template>

<script>
import { defineComponent, ref, reactive, watch, onMounted } from "vue";
import _ from "lodash";

import SvgIcon from "@/components/SvgIcon";

import codeApi from "@/service/api/channelQrcode";

export default defineComponent({
  name: "SelectLiveCodeModal",

  props: {
    visible: Boolean,
    selectedItem: {
      type: Object,
      default: () => {
        return { id: undefined };
      },
    },
  },

  emits: ["update:visible", "confirm"],

  components: {
    SvgIcon,
  },

  setup(props, { emit }) {
    const innerVisible = ref(false);

    watch(
      () => props.visible,
      (newVisible) => {
        innerVisible.value = newVisible;
      }
    );

    watch(
      () => props.selectedItem,
      (value) => {
        const code = _.cloneDeep(value);
        _.assign(selectedCode, code);
      }
    );

    const searchForm = reactive({
      name: "",
      page: 0,
    });

    const livecode = reactive({
      list: [],
      loading: false,
      finished: false,
    });

    const selectedCode = reactive({});

    const listRef = ref();

    const searchCodeByName = () => {
      livecode.list = [];
      livecode.loading = true;
      debounceSearch();
    };

    const debounceSearch = _.debounce(() => {
      livecode.finished = false;
      searchForm.page = 0;

      getCode();
    }, 500);

    const loadMoreCode = _.debounce(() => {
      if (livecode.finished) {
        return;
      }

      const { scrollHeight, scrollTop, clientHeight } = listRef.value;
      if (scrollHeight - scrollTop - clientHeight > 30) {
        return;
      }

      getCode();
    }, 500);

    async function getCode() {
      livecode.loading = true;

      const res = await codeApi.search(searchForm);
      searchForm.page++;

      livecode.list = _.concat(livecode.list, res.data);
      livecode.loading = false;

      livecode.finished = livecode.list.length >= res.total;
    }

    const highlight = (text, keyword) => {
      return _.replace(
        text,
        keyword,
        `<span class="keyword">${keyword}</span>`
      );
    };

    const selectCode = (code) => {
      const { avatar, name: codeName } = code;
      emit("confirm", { avatar, codeName });
    };

    onMounted(() => {
      getCode();
    });

    return {
      innerVisible,

      searchForm,
      livecode,
      selectedCode,

      listRef,
      searchCodeByName,
      getCode,
      loadMoreCode,

      highlight,

      selectCode,
    };
  },
});
</script>
<style lang='less' scoped>
ul,
li {
  list-style: none;
  padding: 0;
}

:deep(.keyword) {
  color: @blue-5;
}
</style>